import { render, staticRenderFns } from "./SlideComp2.vue?vue&type=template&id=3333dc8c&scoped=true&"
import script from "./SlideComp2.vue?vue&type=script&lang=js&"
export * from "./SlideComp2.vue?vue&type=script&lang=js&"
import style0 from "./SlideComp2.vue?vue&type=style&index=0&id=3333dc8c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3333dc8c",
  null
  
)

export default component.exports