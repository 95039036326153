<template>
  <div>
    <!-- main -->
    <!-- 상단슬라이드 
    <template v-if="banner['01'] && banner['01'].length > 0">
        <slide-comp :list="banner['01']" v-slot="slide" class="main-view">
            <div class="main-slide" :class="{'btn_cursor':slide.item.bannerLinkDivCd != '00'}" @click="clickBanner(slide.item)">
                <img :src="'/api/main/bnr/image?bannerSeq='+slide.item.bannerSeq" :alt="slide.item.bannerNm"/>
            </div>
        </slide-comp>
    </template>
    -->
    <!-- 메인섹션 슬라이드 -->
    <!--
    <div id="main_container">
        <slide-comp :list="banner['02']" v-slot="slide" class="service-box" arrowLeft="/images/arrow-left_02.png" arrowRight="/images/arrow-right_02.png">
             <div class="service" v-if="slide.index == 0">
                <div class="main-img">
                    <img src="/images/main_content01.png" alt="메인콘텐츠1"/>
                    <div class="main-text">
                        <span>최고의 IT인재관리 플랫폼!</span><br/>
                        하이프로는 업계 전문기업과 전문가들이 참여하여 검증된 인재와 프로젝트를 연결 해 드립니다.
                    </div>
                    <div class="main-img_text">
                        <p>{{data.tecTotal | number}}</p>
                        <p>{{data.prjTotal | number}}</p>
                        <p>{{data.cliTotal | number}}</p>
                    </div>
                    <p>{{pointFormat(data.pntSaveAmt)}}원</p>  -- 주석처리 해야함
                    <p>{{ data.pntSaveAmt / 10000 | number}}만원</p>
                </div>
            </div> 
            <div class="service" v-if="slide.index != 0">
            <div class="service">
                <div class="main-img" :class="{'btn_cursor':slide.item.bannerLinkDivCd != '00'}" @click="clickBanner(slide.item)">
                    <img :src="'/api/main/bnr/image?bannerSeq='+slide.item.bannerSeq" :alt="slide.item.bannerNm"/>
                </div>
            </div>
        </slide-comp>
    </div>
    -->
    <!-- 메인섹션 프로젝트 
    <div class="mainsection-project" v-if="data.projectList && data.projectList.length > 0">
        <div class="project_container">
            <div class="tit">신뢰받는 우수 고객사의 다양한 프로젝트가 당신을 기다립니다!</div>
            <div class="btn-project"><img src="/images/project_bt.png" alt="프로젝트더보기버튼" @click="$router.move('PRJ101M01')"/></div>
            <div class="project">
                <div class="project-list" v-for="(item, idx) in data.projectList" :key="idx">
                    <div class="project-info-box">
                        <div class="recruting-mark" v-if="item.projectStatus == 1">+ 모집중</div>
                        <div class="recruting-mark bp" v-else-if="item.projectStatus == 3">제안단계</div>
                        <div class="recruting-mark ep" v-else-if="item.projectStatus == 4">수행예정</div>
                        <div class="recruting-mark pf" v-else-if="item.projectStatus == 5">수행중</div>
                        <div class="recruting-mark cp" v-else>완료</div>
                        <div class="project-title">
                            <a href="javascript:void(0)" @click="clickProjectInfo(item)">{{item.projectNm}}</a>
                        </div>
                        <div class="project-contents">
                            <p>
                                수<nbsp :n="2"/>행<nbsp :n="2"/>사<span class="data">{{item.corpNm}}</span><br/><br/>
                                모집인원<span class="data">{{item.persCnt}}명</span><br/><br/>
                                개발기간<span class="data">{{item.projectStartYyyymmdd | date('yyyy.MM.dd')}} ~ {{item.projectEndYyyymmdd | date('yyyy.MM.dd')}}</span>
                            </p>
                        </div>
                        <div class="project-members">
                            <img src="/images/project_mem.png" alt="프로젝트지원자"/>
                            <div class="member-box">
                                <p>{{item.appCnt}}명</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    -->
    <!-- 메인섹션 인트로 -->
    <!--
    <template v-if="banner['03'] && banner['03'].length > 0">
        <slide-comp :list="banner['03']" v-slot="slide" class="mainsection-intro">
            <div class="introbox" :class="{'btn_cursor':slide.item.bannerLinkDivCd != '00'}" @click="clickBanner(slide.item)">
                <img class="mainintro" :src="'/api/main/bnr/image?bannerSeq='+slide.item.bannerSeq" :alt="slide.item.bannerNm"/>
            </div>
        </slide-comp>
    </template>
    -->

    <!-- 메인배너 -->
    <div class="mainsection-mainslide" v-if="banner['01']">
        <slide-comp2 :list="mainBannerList" v-slot="slide" class="slide-wrap">
            <div :class="slide.item.bannerLinkDivCd != '00'? ' slide-item btn_cursor' : 'slide-item'" @click="clickBanner(slide.item)">
                <img :src="'/api/main/bnr/image?bannerSeq='+slide.item.bannerSeq" :alt="slide.item.bannerNm"/>
            </div>
        </slide-comp2>
    </div>

    <!--직무별 프로젝트-->
    <div class="mainsection-prjtask">        
        <div class="inner">            
            <div class="prj-list-wrap">
                
                <div class="prj-keyword">
                    <div style="display: flex; justify-content: space-between;">
                        <div v-if="mberDivCd =='21'" class="tit">소속인재 간편찾기</div>
                        <div v-else-if ="mberDivCd =='22'" class="tit">기술인재 간편찾기</div>
                        <div v-else class="tit">직무별 프로젝트 간편찾기</div>
                        <div class="scroll-btn">
                            <button type="button" class="scroll-left" @click="slidePrev(keywordIdx)">
                                <img src="/images/sm_left_btn.png">
                            </button>
                            <button type="button" class="scroll-right" @click="slideNext(keywordIdx)">
                                <img src="/images/sm_right_btn.png">
                            </button>
                        </div>
                    </div>
                    <div class="keyword">
                        <template v-for="(item, idx) in cdItems">
                            <span :key="idx" v-if="item.cd != ''" @click="goCdItemsPage(item)">{{item.cdNm}}</span>
                        </template>
                    </div>
                </div>

                <!-- 공지사항 -->
                <div v-if="data.news" class="prj-noti">
                    <div class="noti-txt">
                        <a href="javascript:void(0);" @click="goNoticeDetail(data.news.newsCenterSeq)">{{data.news.newsCenterTitle}}</a>
                    </div>
                </div>
            </div>
            <div class="prj-card-wrap">

                <!-- 로그인 전, 기술인재 로그인 -->
                <div class="prj-card" v-if="data.projectList && data.projectList.length > 0">
                    <div class="card-item" v-for="(item, idx) in data.projectList" :key="idx" @click="clickProjectInfo(item)">
                        <div class="flog proc" v-if="item.projectStatus == 1">+ 모집중</div>
                        <div class="flog proc bp" v-else-if="item.projectStatus == 3">제안중</div>
                        <div class="flog proc ep" v-else-if="item.projectStatus == 4">수행예정</div>
                        <div class="flog proc pf" v-else-if="item.projectStatus == 5">수행중</div>
                        <div class="flog proc cp" v-else>완료</div>
                        <div class="txt">
                            <p>{{item.projectNm}}</p>
                            <ul>
                                <li>고 객 사 <span class="client">{{item.corpNm}}</span></li>
                                <li>모집인원 <span>{{item.persCnt}}명</span></li>
                                <li>모집마감 <span>{{item.recrTermEndYyyymmdd | date('yyyy.MM.dd')}}</span></li>
                            </ul>
                        </div>
                    </div>

                    <!-- 프로젝트가 6개 미만일 경우 -->
                    <div class="card-item" v-for="idx in needPrjCnt" :key="idx" style="cursor: default;">
                        <div class="flog procGray">+ 모집중</div>
                        <div class="txt">
                            <p>모집중인 프로젝트가 없습니다!</p>
                            <ul>
                                <li>고 객 사 <span class="client">0000</span></li>
                                <li>모집인원 <span>0명</span></li>
                                <li>모집마감 <span>0000.00.00</span></li>
                            </ul>
                        </div>
                    </div>
                </div>


                <!-- 로그인 전, 기술인재 로그인_등록된 프로젝트가 0개일 경우 -->
                <div class="prj-card" v-else-if="data.projectList && data.projectList.length == 0 && (mberDivCd =='11' || mberDivCd =='')">
                    <!-- 프로젝트가 6개 미만일 경우 -->
                    <div class="card-item" v-for="idx in needPrjCnt" :key="idx" style="cursor: default;">
                        <div class="flog procGray">+ 모집중</div>
                        <div class="txt">
                            <p>모집중인 프로젝트가 없습니다!</p>
                            <ul>
                                <li>고 객 사 <span class="client">0000</span></li>
                                <li>모집인원 <span>0명</span></li>
                                <li>모집마감 <span>0000.00.00</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- 수요기업_관리총괄 로그인시 -->
                <div class="prj-card" v-else-if="mberDivCd =='21'">
                    <div class="card-item2 card-img11" @click="goPage('/PRJ201M01')">
                        <div class="txt">
                            <p>프로젝트 담당자가 등록한 공급기업 지정 프로젝트를 <br/>확인할 수 있습니다!</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>전체 프로젝트</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img12" @click="goPage('/SRH201M01')">
                        <div class="txt">
                            <p>소속 기술인재의 정보를 확인하여 관리해보세요!</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>소속 인재 현황</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img13" @click="goHtuPop('0', '0')">
                        <div class="txt">
                            <p>하이프로의 이용방법이 궁금하세요?</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>이용방법 알아보기</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img14" @click="goPage('/PRJ204M01')">
                        <div class="txt">
                            <p>프로젝트 담당자가 등록한 일반 프로젝트를 <br/>확인할 수 있습니다!</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>전체 프로젝트</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img15" @click="goPage('/MYP201M01')">
                        <div class="txt">
                            <p>프로젝트 담당자 정보 및 승인관리를 하실 수 <br/>있습니다!</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>마이페이지 가기</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img16" @click="goPage('/FAQ005M01')">
                        <div class="txt">
                            <p>다음 프로젝트 계약 시 <br/>계약금 할인 서비스</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>포인트 제도 알아보기</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 수요기업_프로젝트 담당 로그인시 -->
                <div class="prj-card" v-else-if="mberDivCd =='22'">
                    <div class="card-item2 card-img01" @click="goPage('/SRH301M01')">
                        <div class="txt">
                            <p>프로젝트에 적합한 검증된 기술인재를 검색해 보세요!</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>기술인재 찾기 서비스</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img02" @click="goHtuPop('0', '2')">
                        <div class="txt">
                            <p>프로젝트 구분에 따라 프로젝트를 등록해 보세요!</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>프로젝트 등록하기</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img03" @click="goHtuPop('0', '0')">
                        <div class="txt">
                            <p>하이프로의 이용방법이 궁금하세요?</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a >이용방법 알아보기</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img04" @click="goPage('/PRJ305M01')">
                        <div class="txt">
                            <p>등록하신 프로젝트에 지원한 기술인재를 확인해 보세요!</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>일반 프로젝트 지원인재</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img05" @click="goPage('/PRJ306M01')">
                        <div class="txt">
                            <p>기술인재 인터뷰 요청 결과를 확인하실 수 있습니다!</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>프로젝트 인터뷰 요청</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img06" @click="goPage('/PRF301M01')">
                        <div class="txt">
                            <p>관심있는 기술인재 정보를 한곳에 모아서 확인하세요!</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>관심인재 보러가기</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 수요기업_공급기업 로그인시 -->
                <div class="prj-card" v-else-if="mberDivCd =='31'">
                    <div class="card-item2 card-img21" @click="goPage('/SRH401M01')">
                        <div class="txt">
                            <p>소속 기술인재의 정보를 확인하여 관리해 보세요!</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>소속 인재 현황</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img22" @click="goPage('/PRJ405M01')">
                        <div class="txt">
                            <p>소속 기술인재에 적합한 프로젝트를 확인해 보세요!</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>프로젝트 찾기</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img23" @click="goHtuPop('2', '0')">
                        <div class="txt">
                            <p>하이프로 이용방법이 궁금하세요?</p>
                            <div class="link-txt">
                                <span class="btn-more">
                                    <a>이용방법 알아보기</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img24" @click="goPage('/MYP401M01')">
                        <div class="txt">
                            <p>신속한 기술인재 승인관리는 효과적인 프로젝트 참여를 가능하게 합니다!</p>
                            <div class="link-txt m-t22">
                                <span class="btn-more">
                                    <a>소속 기술인재 승인관리</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img25" @click="goPage('/PRJ406M01')">
                        <div class="txt">
                            <p>내 소속사에 지정된 프로젝트를 확인하여 <br/>진행해 보세요!</p>
                            <div class="link-txt m-t22">
                                <span class="btn-more">
                                    <a>프로젝트 확인</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item2 card-img26" @click="goPage('/PRJ407M01')">
                        <div class="txt">
                            <p>공급기업 지정 프로젝트의 진행단계를 확인해 보세요!</p>
                            <div class="link-txt m-t22">
                                <span class="btn-more">
                                    <a>진행단계별 프로젝트</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 중단배너 -->
                <div v-if="banner['02']" :class="banner['02'][0].bannerLinkDivCd != '00'? 'prj-mid-banr btn_cursor' : 'prj-mid-banr'" style="width: 228px;">
                    <div>
                        <!-- <img src="/images/mid_bnr_01.png"> -->
                        <ImageComp :src="'/api/main/bnr/image?bannerSeq='+banner['02'][0].bannerSeq" alt="중단배너 이미지"   @click="clickBanner(banner['02'][0])" />
                    </div>
                </div>
                <div class="prj-info">
                    <div class="info_box">
                        <div><img src="/images/icon_prj_info_01.png"></div>
                        <div class="info_num c1">
                            {{data.tecTotal}}
                            <span>IT 전문인재</span>
                        </div>
                    </div>
                    <div class="info_box">
                        <div><img src="/images/icon_prj_info_02.png"></div>
                        <div class="info_num c2">
                            {{data.prjTotal}}
                            <span>누적 프로젝트</span>
                        </div>
                    </div>
                    <div class="info_box">
                        <div><img src="/images/icon_prj_info_03.png"></div>
                        <div class="info_num">
                            {{data.cliTotal}}
                            <span>고객사</span>
                        </div>
                    </div>
                    <div class="info_box total">
                        <div class="tot-tit">누적 적립금</div>
                        <div class="info_num c3">
                            <span class="coin"><img src="/images/icon_point.png"></span>
                            {{data.pntSaveAmt | number}}                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!--추천 프로젝트-->
    <div class="mainsection-mainprj"  v-if="data.recomendProjectList && data.recomendProjectList.length > 0">
        <div class="inner">
            <div class="tit">
                추천 프로젝트
                <!-- <span class="btn-more">
                    <a href="javascript:void(0);">전체보기</a>
                </span> -->
            </div>
            <div class="prj-list">
                
                <div class="card" v-for="(item, idx) in data.recomendProjectList" :key="idx" @click="clickProjectInfo(item)">
                    <div class="card-img">
                        <ImageComp :src="'/api/main/bnr/recmendProjectImg?recmendProjectSeq='+item.recmendProjectSeq" alt="추천프로젝트 배너이미지" />
                    </div>
                    <div class="card-txt">
                        <div class="txt">
                            <p>{{ item.projectNm }}</p>
                            <ul>
                                <li>모집인원 <span>{{ item.persCnt }}명</span></li>
                                <li>모집마감 <span>{{ item.recrendYyyyMmDd }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!--메인섹션 배너 / 로그인 전후 배너가 다릅니다 / 관리자에서 등록한 배너 노출-->
    <div v-if="banner['03']" class="mainsection-mainbanr"  :style="$store.state.userInfo.mberSeq != '' ? 'background-color : #ffffff;'  : 'background-color : #9898ea;'" >
        <div :class="banner['03'][0].bannerLinkDivCd != '00'? 'banr btn_cursor' : 'banr'">
            <ImageComp :src="'/api/main/bnr/image?bannerSeq='+banner['03'][0].bannerSeq" alt="메인섹션 배너이미지"  @click="clickBanner(banner['03'][0])"/>
        </div>
    </div>	

    <!-- 메인섹션 고객만족도 -->
    <div class="mainsection-customer" v-if="data.evalList && data.evalList.length > 0">
        <div class="tit">하이프로는 고객만족도가 높습니다!</div>
        <slide-comp :size="data.evalList.length / evalVueCnt" v-slot="slide" class="review-box">
            <template v-for="idx in 8">
                <div class="review-list" v-if="slide.index * evalVueCnt + idx - 1 < data.evalList.length" :key="slide.index * evalVueCnt + idx - 1" :style="{ 'margin-left': '1px'}">
                    <div class="imagebox"><ImageComp type="image" :src="'/api/mem/myp/corpImageDown?corpSeq=' + data.evalList[slide.index * evalVueCnt + idx - 1].corpSeq" defaultSrc="COMPANY"/></div>
                    <eval-div-grade class="pointbox" :value="data.evalList[slide.index * evalVueCnt + idx - 1].itemDivGrade">
                        <template v-slot="slot">
                            <div class="point-list">
                                <SelectComp tagName="p" type="text" :value="slot.item.cd" cdId="PRO110"/>
                                <div class="star"><grade-img type="CLIENT_EVAL" :grade="slot.item.grade"/></div>
                            </div>
                        </template>
                        <template v-slot:empty><div class="eval none"><img src="/images/tec/myp/evaluation_none.png" alt="없음"/></div></template>
                    </eval-div-grade>
                    <div class="customer-name">{{data.evalList[slide.index * evalVueCnt + idx - 1].corpNm}}</div>
                    <nbsp tagName="div" class="review-contents" :value="data.evalList[slide.index * evalVueCnt + idx - 1].overallOpinCont" />
                    <div class="manager-name">
                        <p>담당자</p><span class="data">{{data.evalList[slide.index * evalVueCnt + idx - 1].loginId}}</span>
                    </div>
                </div>
            </template>
        </slide-comp>
    </div>
    <!-- 메인섹션 파트너 -->
    <div class="mainsection-partner">
        <div class="partner-box">
            <div class="tit">신뢰높은 고객사와 함께 합니다!</div>
            <slide-comp :list="partnerSlide" v-slot="slide" class="partner-container">
                <div class="partner-image"><img :src="slide.item.img" :alt="slide.item.desc"/></div>
            </slide-comp>
        </div>
    </div>
  </div>
</template>

<script>
import slideComp from "@/components/SlideComp.vue";
import slideComp2 from "@/components/SlideComp2.vue";
import gradeImg from "@/components/highpro/GradeImg.vue";
import evalDivGrade from "@/components/highpro/EvalDivGrade.vue";

export default {
    components: {
        slideComp, slideComp2, gradeImg, evalDivGrade
    },
	data() {
		return {
            now: new Date().format("yyyyMMdd"),
            prjVueCnt : 6,
            evalVueCnt : 8,
		    data : {},
            // banner: { '02': [{}]},
            banner: { }, // 중단배너 첫화면 임시 제거
            partnerSlide : [
                { img: '/images/partner.png', desc:'행정안정부, KB국민은행, KB국민카드, KB저축은행, KEB하나은행, KAKAO, 신한은행, 우리은행, NH농협, 롯데카드, 우정사업본부, 한국주택금융공사, 미소금융중앙재단, 웰컴저축은행, SGI서울보증, KoFC한국정책금융공사, KORES한국광물자원공사, 메리츠종금증권, 한국투자 밸류자산운용' },
            ],
			list : [],

            originCdItems: [],
            cdItems : [],
            keywordSize : 6,
            keywordIdx : 0,
            

            mberDivCd : '',
            needPrjCnt : 6,

            mainBannerList : []
            
		}
	},
	beforeMount(){
		//console.log('GUD001P01', '===== beforeMount =====', this.param);
		if(this.param){
			this.text = this.param.text || '';
			this.list = this.param.list || [];
		}

        this.mberDivCd = this.$store.state.userInfo.mberDivCd;
	},
    created() {
        // this.$.httpPost("/api/main/")
        //     .then(res => {
        //         this.data = res.data || {};
        //         for(var i in this.data.banner){
        //             var item = this.data.banner[i];
        //             if(!Array.isArray(this.banner[item.bannerDivCd])){
        //                 this.banner[item.bannerDivCd] = [];
        //             }
        //             this.banner[item.bannerDivCd].push(item);
        //         }
        //     }).catch(this.$.httpErrorCommon);
    },
	mounted(){
        this.$.httpPost("/api/main/")
            .then(res => {

                this.data = res.data || {};
                for(var i in this.data.banner){
                    var item = this.data.banner[i];
                    if(!Array.isArray(this.banner[item.bannerDivCd])){
                        this.banner[item.bannerDivCd] = [];
                    }
                    this.banner[item.bannerDivCd].push(item);
                }
                
                this.mainBannerList = [ ...this.banner['01']
                                        , ...this.banner['01']
                                        , ...this.banner['01']]

                if(this.data.projectList) this.needPrjCnt = this.needPrjCnt - this.data.projectList.length;
                
            }).catch(this.$.httpErrorCommon);

            this.$store.dispatch("commonCode").then((res) => {
                if(this.mberDivCd == '21' || this.mberDivCd == '22'){

                    this.originCdItems = [{ cd : 'Y',
                                            cdId : 'joinPossList',
                                            cdNm : '참여가능',
                                          },
                                         ...res.PRO106,
                                         ...res.PRO121 ];
                }else {
                    this.originCdItems = res.PRO141;
                }

                this.cdItems = this.originCdItems;
            });

	},
	methods: {
		clickProjectInfo(item) {
			if(this.$store.state.userInfo.mberSeq) {
                var pageId = 'PRJ101M02';
                if(this.mberDivCd == '31') pageId = 'PRJ405M03';
                
				this.$router.push({name : pageId, query : {projectSeq : item.projectSeq}});
			} else {
				this.$.popup('/tec/prj/PRJ101P03')
					.then(res => {
						if(res) {
							// 회원가입 화면으로 이동
							this.$router.push({name : 'MEM003M01'});
						}
					});
			}
        },
        pointFormat(val){
            var result = '';
            var num = parseInt((val + '').replace(/[^0-9]/g, ''), 10) + '';
            var unitCnt = Math.ceil(num.length / 4); //단위 갯수. 숫자 10000은 일단위와 만단위 2개이다.
            num = num.padStart(unitCnt * 4, '0') //4자리 값이 되도록 0을 채운다
            var arr = num.match(/[\w\W]{4}/g);

            for(var i in arr){
                if(i > 1) break;
                result += this.$format.number(parseInt(arr[i])) + this.$format.ARR_NUMBER_KOR[unitCnt - i - 1] + ' ';
            }
            return result.trim();
        },
        clickBanner(item){
            var url = '';
            switch(item.bannerLinkDivCd){
                case '01':
                    url = '/MAN001M02?bannerSeq='+item.bannerSeq;
                    break;
                case '02':
                    url = item.bannerLinkUrl;
                    break;
            }

            if(url){
                if(item.bannerTargetDivCd == '01'){
                    window.open(url, '_blank')
                } else {
                    this.$router.push(url);
                }
            }
        },
        goNoticeDetail(idx) {
            this.$router.push({name: 'BBS002M02', params:{newsCenterSeq:idx}});
        },

        goPage(bannerLinkUrl) {
            this.$router.push(bannerLinkUrl);
        },

        goHtuPop(tabIdx, itemIdx){
            
            // this.$router.push({name: 'HTU001P01', params:{tabIdx : tabIdx, itemIdx:itemIdx}});
            
            var param = {
                tabIdx : tabIdx, 
                itemIdx : itemIdx
            }
            
            this.$.popup('/man/htu/HTU001P01', param);
        },

        // 간편찾기 슬라이드
        slideNext(idx){ 
			if((this.originCdItems.length - idx) == this.keywordSize) return;
			else{
                this.keywordIdx ++;
                this.cdItems = this.originCdItems.slice(this.keywordIdx);
            }
		},

		slidePrev(idx){
            if(idx == 0) return;
			else{
                this.keywordIdx --;
                this.cdItems = this.originCdItems.slice(this.keywordIdx);
            }
		},

        goCdItemsPage(item){
            var pageId = 'PRJ101M01';

            if(this.mberDivCd == '31') pageId = 'PRJ405M01';
            if(this.mberDivCd == '21') pageId = 'SRH201M01';
            if(this.mberDivCd == '22') pageId = 'SRH301M01';

            this.$router.push({name: pageId, params:item});
        },

	},
};
</script>